import { country } from "~/constants/apiRoute/master"

export const getCountries = async (options: any = {}): Promise<IResponder> =>
  await useApiFetch(`${country}`, {
    query: options,
  })
export const postCountry = async (
  body: any,
  options: any
): Promise<IResponder> => await useSave(`${country}`, body, options)

export const showCountry = async (id: string): Promise<IResponder> =>
  await useApiFetch(`${country}/${id}`)
